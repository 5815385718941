import classNames from 'classnames'
import { map, range, round } from 'lodash-es'

import { EVALUATIONS } from '../../../common/reviews'
import { ReactComponent as Star } from '../../../style/assets/star.svg'

import './style.css'

interface Prop {
  stars: number
  animated?: boolean
  evaluation?: boolean
}

const Rating = ({ stars, animated, evaluation }: Prop) => {
  const roundedRating = round(stars, 1)
  const formatter = Intl.NumberFormat('de', { notation: 'compact' })

  return (
    <div className={classNames('rating', { 'rating--pulsing': animated })}>
      <div className="rating__stars">
        {map(range(1, 11), (x: number, idx) => (
          <div
            key={`starRaiting${idx}`}
            className={classNames(
              x % 2 === 0
                ? 'rating__stars--right-star'
                : 'rating__stars--left-star',
              {
                'rating__stars--selected':
                  x <= Number.parseInt(formatter.format(roundedRating * 2)),
              }
            )}
          >
            {<Star />}
          </div>
        ))}
      </div>
      {evaluation && (
        <span className="rating__evaluation">{`${EVALUATIONS} оценки`}</span>
      )}
    </div>
  )
}

export default Rating
