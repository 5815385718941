import { useRef, useState } from 'react'
import { Link, LinkProps } from 'react-router-dom'

import classnames from 'classnames'
import { MdMenu } from 'react-icons/md'

import useDeviceSize from '../../../common/hooks/useDeviceSize'
import useOnClickOutside from '../../../common/hooks/useOnOutsideClick'
import { ReactComponent as Logo } from '../../../style/assets/Logo_test.svg'
import './style.css'

type MenuItem = {
  text: string
  onClick?: () => void
}

type NavItem = {
  itemName: string
} & Pick<LinkProps, 'to'>

interface Prop {
  menuItems?: MenuItem[]
  rightSection?: NavItem[]
}

const Header = ({ menuItems = [], rightSection = [] }: Prop) => {
  const [isOpen, setIsOpen] = useState(false)
  const { isSmallDevice } = useDeviceSize()
  const headerRef = useRef(null)

  const toggleIsOpen = () => setIsOpen(!isOpen)

  useOnClickOutside(headerRef, toggleIsOpen, isOpen)

  return (
    <header className="header" ref={headerRef}>
      <div className="header__main__left">
        {menuItems.length > 0 && (
          <button
            className="header__main__left__btn"
            onClick={toggleIsOpen}
            aria-label="Меню"
          >
            <MdMenu size="2.4rem" color="black" />
          </button>
        )}
      </div>
      <div className="header__main__center">
        <Link to="/home">
          <Logo className="header__main__logo" />
        </Link>
      </div>
      <nav className="header__main__right">
        <ul>
          {rightSection.map((x, idx) => (
            <li key={`x.ot${idx}`}>
              <Link to={x.to}>{x.itemName}</Link>
            </li>
          ))}
        </ul>
      </nav>
      {menuItems.length > 0 && (
        <div
          className={classnames('header__menu', {
            'header__menu--hidden': !isOpen,
          })}
        >
          <ul
            className={classnames('header__menu__list', {
              'header__menu__list--mobile': isSmallDevice,
            })}
          >
            {menuItems.map((x: MenuItem, idx: number) => (
              <li
                className="header__menu__list__item"
                onClick={() => {
                  if (x.onClick !== undefined) {
                    x?.onClick()
                  }
                  toggleIsOpen()
                }}
                key={`headerItem${idx}`}
              >
                {x.text}
              </li>
            ))}
          </ul>
        </div>
      )}
    </header>
  )
}

export default Header
export type { MenuItem, NavItem }
