import Article from '../../../framework/article'
import Section from '../../../framework/section'
import { OUR_VISION_TEXT, OUR_VISION_TITLE } from './our-vision-text'

import './style.css'

const OurVisionSection = () => {
  return (
    <Section>
      <div className="our-vision-section">
        <Article title={OUR_VISION_TITLE} text={OUR_VISION_TEXT} />
      </div>
    </Section>
  )
}

export default OurVisionSection
