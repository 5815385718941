import { useState } from 'react'

import { map } from 'lodash-es'

import { REVIEWS } from '../../../common/reviews'
import Review from '../review'
import './style.css'

const VISIBLE_REVIEW = 4

const ReviewList = () => {
  const [visible, setVisible] = useState(VISIBLE_REVIEW)

  return (
    <div className="review-list__container">
      <ul className="review-list">
        {map(REVIEWS.slice(0, visible), (x, idx) => (
          <Review
            key={`review${idx}`}
            review={x.review}
            rating={x.rating}
            userName={x.user_name}
          />
        ))}
      </ul>
      {visible <= REVIEWS.length && (
        <button
          className="review-list__load-more"
          onClick={() => setVisible((x) => x + VISIBLE_REVIEW)}
        >
          Още ревюта
        </button>
      )}
      <span className="review-list__footer">
        Оригиналните ревюта може да видите&nbsp;
        <a
          href="https://grabo.bg/places/martys-cleaning/reviews"
          target="_blank"
          rel="noreferrer"
        >
          тук!
        </a>
      </span>
    </div>
  )
}

export default ReviewList
