import { forwardRef } from 'react'

import {
  EMAIL,
  HALF_WORKING_DAY,
  HALF_WORKING_HOURS,
  PHONE_NUMBER,
  WORKING_DAYS,
  WORKING_HOURS,
} from '../../../../common/factory/contacts'

import './style.css'

const FooterSection = forwardRef<HTMLDivElement>((_, ref) => {
  return (
    <footer className="footer-section" ref={ref}>
      <div className="footer-section__column">
        <span>
          <strong>КОНТАКТИ:</strong>
        </span>
        <span>
          <strong>Тел:</strong>
          {` ${PHONE_NUMBER}`}
        </span>
        <span>
          <strong>Email:</strong>
          {` ${EMAIL}`}
        </span>
      </div>
      <div className="footer-section__column">
        <span>
          <strong>РАБОТНО ВРЕМЕ:</strong>
        </span>
        <span>
          <strong>{`${WORKING_DAYS}:`}</strong>
        </span>
        <span>{`${WORKING_HOURS}`}</span>
        <span>
          <strong>{`${HALF_WORKING_DAY}:`}</strong>
        </span>
        <span>{`${HALF_WORKING_HOURS}`}</span>
      </div>
    </footer>
  )
})

FooterSection.displayName = 'FooterSection'

export default FooterSection
