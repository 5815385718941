import { useState } from 'react'
import {
  autoUpdate,
  offset,
  useFloating,
  flip,
  shift,
  useHover,
  useFocus,
  useDismiss,
  useRole,
  useInteractions,
  FloatingPortal,
} from '@floating-ui/react'
import { GiRotaryPhone } from 'react-icons/gi'
import { FaStarOfLife } from 'react-icons/fa'

import {
  HALF_WORKING_DAY,
  HALF_WORKING_HOURS,
  PHONE_NUMBER,
  WORKING_DAYS,
  WORKING_HOURS,
} from '../../../common/factory/contacts'
import { RATING } from '../../../common/reviews'
import Rating from '../rating'

import './style.css'

const Card = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const { x, y, refs, strategy, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    placement: 'top-end',
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(5),
      flip({
        fallbackAxisSideDirection: 'start',
      }),
      shift(),
    ],
  })

  const hover = useHover(context, { move: false })
  const focus = useFocus(context)
  const dismiss = useDismiss(context)

  const role = useRole(context, { role: 'tooltip' })

  const { getReferenceProps, getFloatingProps } = useInteractions([
    hover,
    focus,
    dismiss,
    role,
  ])

  return (
    <aside className="card">
      <div className="card__header">
        <h2>РАБОТНО ВРЕМЕ</h2>
        <div
          className="card__additional-info"
          ref={refs.setReference}
          {...getReferenceProps()}
        >
          <FaStarOfLife size={'16px'} color="#005EA8" />
        </div>
        <FloatingPortal>
          {isOpen && (
            <div
              ref={refs.setFloating}
              className="card__additional-info__tooltip"
              style={{ position: strategy, top: y ?? 0, left: x ?? 0 }}
              {...getFloatingProps()}
            >
              {'Извън работно време и празнични дни - цена по договаряне.'}
            </div>
          )}
        </FloatingPortal>
      </div>
      <div className="card__body">
        <div className="card__body__time-slot">
          <h3 className="card__body__time-slot__title">{WORKING_DAYS}</h3>
          <span className="card__body__time-slot__subtitle">
            {WORKING_HOURS}
          </span>
        </div>
        <div className="card__body__time-slot">
          <h3 className="card__body__time-slot__title">{HALF_WORKING_DAY}</h3>
          <span className="card__body__time-slot__subtitle">
            {HALF_WORKING_HOURS}
          </span>
        </div>
        <span className="card__footer">
          <GiRotaryPhone size={25} style={{ marginRight: '4px' }} />
          {PHONE_NUMBER}
        </span>
      </div>
      <span className="card__footer">
        <Rating stars={RATING} animated={true} evaluation={true} />
      </span>
    </aside>
  )
}

export default Card
