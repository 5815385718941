import Section from '../../../framework/section'

import './style.css'

const ExplanationSection = () => {
  return (
    <Section>
      <div className="explanation-section__text">
        <p>
          Професионалното пране на мебели постига чистотата, която обикновеното
          чистене на ръка не успява.
          <br />
          <br />
          Прането се осъществява с професионални машини, разработени специално
          за прането на меки мебели. Препаратите, които използваме в работата
          си, са Немски екологично чисти. Те щадят и самата тъкан на мебелите.
          <span className="explanation-section__title">
            Какво представлява прането?
          </span>
          Самият процес се състои от безпрахово изтупване и прахосмукиране в
          дълбочина със специалните накрайници на машината, нанасяне на
          висококачествен професионален перилен препарат, изпиране и
          изсушаването на меките мебели, матраците и килимите.
          <br />
          <br />
          Машинното пране е подходящо за почти всички видове мека мебел у дома и
          в офиса:
          <br />
          <br />
        </p>
        <div className="explanation-section__services__wrapper">
          <ul className="explanation-section__services">
            <li>холни гарнитури</li>
            <li>ъглови и разтегателни дивани</li>
            <li>фотьойли</li>
            <li>канапета</li>
            <li>мебели с релакс механизъм</li>
            <li>спални</li>
            <li>кухненски ъгли</li>
            <li>табуретки</li>
            <li>всички видове тапицирани столове</li>
          </ul>
        </div>
      </div>
    </Section>
  )
}

export default ExplanationSection
