import { Link } from 'react-router-dom'
import { Image } from '../../../common/type/image'

import './style.css'

interface Prop {
  postDate: Date
  readInMin: number
  title: string
  image: Image
  to: string
}

const Box = ({ postDate, readInMin, title, image, to }: Prop) => {
  return (
    <div className="box hvr-float">
      <Link to={to}>
        <img
          className="box__img"
          loading="lazy"
          width={300}
          height={200}
          alt={image.alt}
          src={image.src}
        />
      </Link>
      <p className="box__date-and_read-time">{`${postDate.toLocaleString('bg', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      })}, ${readInMin} мин четене`}</p>
      <p className="box__title-wrapper">
        {
          <Link to={to} className="box__title-wrapper__title">
            {title}
          </Link>
        }
      </p>
    </div>
  )
}

export default Box
