import { blogPostMetadata } from '../../../common/factory/blog-post-metadata'
import Box from '../../framework/box'

import './style.css'

const Blog = () => {
  return (
    <>
      <div className="blog-screen">
        <main>
          <div className="blog-screen__header">
            <h1>Блог</h1>
          </div>
          <div className="blog-layout">
            {blogPostMetadata.map(
              ({ postDate, to, image, readInMin, title }, idx) => (
                <Box
                  key={`blogpost${idx}`}
                  title={title}
                  image={image}
                  readInMin={readInMin}
                  postDate={postDate}
                  to={to}
                />
              )
            )}
          </div>
        </main>
      </div>
    </>
  )
}

export default Blog
