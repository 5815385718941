const EMAIL = 'martyscleaning1986@gmail.com'
const FACEBOOK = 'https://m.me/107772038588625'
const PHONE_NUMBER = '+359 877 777 488'
const WORKING_HOURS = 'От 8:00ч до 18:00ч'
const WORKING_DAYS = 'Понеделник - Петък'
const HALF_WORKING_DAY = 'Събота'
const HALF_WORKING_HOURS = 'От 8:00ч до 12:00ч'
const COMPANY_NAME = '"Мартис Клийнинг" EОOД'

export {
  EMAIL,
  FACEBOOK,
  PHONE_NUMBER,
  WORKING_HOURS,
  WORKING_DAYS,
  COMPANY_NAME,
  HALF_WORKING_DAY,
  HALF_WORKING_HOURS,
}
