export const ABOUT_US_TEXT = `
            MartysCleaning е фирма за професионално почистване, която се стреми да предостави на клиентите си най-качествените услуги в областта на почистването. Ние предлагаме широка гама от услуги, включително пране на мека мебел, почистване на килими и тапицерии, миене на прозорци, почистване на автомобили, почистване след ремонт и много други.

            Нашата фирма е създадена с идеята да предостави на клиентите си услуги, които са бързи, ефективни и достъпни. За тази цел, ние работим с опитни професионалисти в сферата на почистването, които използват най-съвременната техника и продукти за почистване. Ние се гордеем с нашата работа и винаги се стремим да постигнем най-добрите резултати.

            В MartysCleaning разбираме, че всяка сграда или дом има свои специфични нужди, поради което предлагаме персонализирани решения за всеки клиент. Нашите услуги са насочени към домове, офиси, магазини, ресторанти и много други места. Ние вярваме, че професионалното почистване е необходимо за удобството и здравето на всеки човек, поради което нашата мисия е да осигурим безопасна и здравословна среда за нашите клиенти. Ние работим усилено, за да гарантираме, че нашите услуги са най-качествените в бранша на почистването, за да можем да постигнем високото ниво на доверие и удовлетвореност на нашите клиенти.

            С MartysCleaning ще получите професионално почистване на най-високо ниво, което е съобразено с вашите специфични нужди и изисквания.
  `

export const ABOUT_US_TITLE = 'Кои сме ние ?'
