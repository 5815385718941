import BlogImg1 from '../../style/assets/blog_img1.jpeg'
import BlogImg1Social from '../../style/assets/blog_img1_960w.jpeg'
import BlogImg2 from '../../style/assets/blog_img2.jpeg'
import BlogImg2Social from '../../style/assets/blog_img2_960w.jpeg'
import BlogImg3 from '../../style/assets/blog_img3.jpeg'
import BlogImg3Social from '../../style/assets/blog_img3_960w.jpeg'
import BlogImg4 from '../../style/assets/blog_img4.jpeg'
import BlogImg4Social from '../../style/assets/blog_img4_960w.jpeg'
import BlogImg5 from '../../style/assets/blog_img5.jpeg'
import BlogImg5Social from '../../style/assets/blog_img5_960w.jpeg'
import { BlogMetadata } from '../type/blog-metadata'

export const blogPostMetadata: BlogMetadata[] = [
  {
    postDate: new Date('2/20/2023'),
    title: 'Ползите от редовното пране на матраци',
    readInMin: 1,
    image: {
      src: BlogImg2,
      srcSocial: BlogImg2Social,
      alt: 'спалня с светло кафяви стени и под. Стаята съдържа легло с бяло покривало, възглавници и пейка',
      author: {
        name: 'Kenny Eliason',
        url: 'https://unsplash.com/@neonbrand',
      },
    },
    to: '/blog/polzite-ot-redovnoto-prane-na-matraci',
  },
  {
    postDate: new Date('3/1/2023'),
    title: 'Ползите от основното почистване на дома',
    readInMin: 1,
    image: {
      src: BlogImg1,
      srcSocial: BlogImg1Social,
      alt: 'ръка в ръкавица, държаща спрей бутилка',
      author: {
        name: 'Towfiqu barbhuiya',
        url: 'https://unsplash.com/@towfiqu999999',
      },
    },
    to: '/blog/polzite-ot-osnovnoto-pochistvane-na-doma',
  },
  {
    postDate: new Date('3/30/2023'),
    title: 'Ползите от професионалното почистване на дома',
    readInMin: 2,
    image: {
      src: BlogImg4,
      srcSocial: BlogImg4Social,
      alt: 'ръка в ръкавица, държаща спрей бутилка',
      author: {
        name: 'JESHOOTS.COM',
        url: 'https://unsplash.com/@jeshoots',
      },
    },
    to: '/blog/polzite-ot-profesionalnoto-pochistvane-na-doma',
  },
  {
    postDate: new Date('4/7/2023'),
    title: 'Какво ни отличава от конкуренцията',
    readInMin: 1,
    image: {
      src: BlogImg5,
      srcSocial: BlogImg5Social,
      alt: 'ръка на жена, която пише в тетратка',
      author: {
        name: 'Glenn Carstens-Peters',
        url: 'https://unsplash.com/@glenncarstenspeters',
      },
    },
    to: '/blog/kakvo-ni-otlichava-ot-konkurenciqta',
  },
  {
    postDate: new Date('5/17/2023'),
    title: '10 причини, поради които да перете редовно дивана си',
    readInMin: 2,
    image: {
      src: BlogImg3,
      srcSocial: BlogImg3Social,
      alt: 'уютна всекидневна със сив диван, възглавници и дървена маса',
      author: {
        name: 'Nathan Fertig',
        url: 'https://unsplash.com/@nathanfertig',
      },
    },
    to: '/blog/10-prichini-poradi-koito-da-perete-redovno-divana-si',
  },
]
