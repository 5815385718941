import { Outlet } from 'react-router-dom'
import Header from '../components/framework/header'
import CopyrightSection from '../components/presentational/sections/copyright-section'

export const BlogRoute = () => {
  return (
    <>
      <Header />
      <Outlet />
      <CopyrightSection />
    </>
  )
}
