import Rating from '../rating'
import './style.css'

type Stars = 1 | 2 | 3 | 4 | 5

interface Prop {
  review: string
  rating: Stars
  userName: string
}

const Review = ({ review, rating, userName }: Prop) => (
  <li className="review">
    <span className="review__username-rating">
      {userName}
      {<Rating stars={rating} />}
    </span>
    <span className="review__text">{review}</span>
  </li>
)

export default Review
export type { Stars }
