import { ReactNode } from 'react'
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
} from 'react-share'
import { BlogMetadata } from '../../../common/type/blog-metadata'
import { Helmet } from 'react-helmet'

import './style.css'

type Prop = BlogMetadata & { children?: ReactNode }

const BlogPost = ({
  title,
  image,
  readInMin,
  postDate,
  children,
  to,
}: Prop) => {
  const urlBlogPost = `www.martyscleaning.bg/blog${to}`

  return (
    <main className="blog-post">
      <Helmet>
        <meta property="og:image" content={image.srcSocial} />
        <meta property="og:image:alt" content={image.alt} />
        <meta property="og:title" content={title} />
        <meta property="og:url" content={urlBlogPost} />
        <meta property="og:type" content="article" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:image" content={image.srcSocial} />
      </Helmet>
      <h1 className="blog-post__title">{title}</h1>
      <p className="blog-post__subtitle">{`${postDate.toLocaleString('bg', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      })}, ${readInMin} мин четене`}</p>
      <section className="blog-post__section">
        <div className="blog-post__section__socials">
          <FacebookShareButton
            url={urlBlogPost}
            className="blog-post__section__socials__fb"
          >
            <FacebookIcon size={32} round></FacebookIcon>
          </FacebookShareButton>
          <TwitterShareButton
            url={urlBlogPost}
            className="blog-post__section__socials__fb"
          >
            <TwitterIcon size={32} round></TwitterIcon>
          </TwitterShareButton>
        </div>
        <img
          className="blog-post__section__img"
          src={image.src}
          alt={image.alt}
          width={960}
          height={480}
        />
        <div className="blog-post__section__photograph">
          {`Източник: `}
          <a href={image.author.url} target="_blank" rel="noreferrer">
            {image.author.name}
          </a>
        </div>
        {children}
      </section>
    </main>
  )
}

export default BlogPost
