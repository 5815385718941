import CarouselComp, { Alignment } from 'nuka-carousel'

import useDeviceSize from '../../../common/hooks/useDeviceSize'
import BeforeAfter1 from '../../../style/assets/before_after_1.webp'
import BeforeAfter2 from '../../../style/assets/before_after_2.webp'
import BeforeAfter3 from '../../../style/assets/before_after_3.webp'
import BeforeAfter4 from '../../../style/assets/before_after_4.webp'
import BeforeAfterCouch1 from '../../../style/assets/Before_and_After_Couch.jpg'
import BeforeAfterCouch2 from '../../../style/assets/Before_and_After_Couch2.jpg'
import BeforeAfterCouch3 from '../../../style/assets/Before_and_After_Couch3.jpg'
import BeforeAfterCouch4 from '../../../style/assets/Before_and_After_Couch4.jpg'
import BeforeAfterMattress from '../../../style/assets/Before_and_After_Mattress.jpg'
import BeforeAfterPillow from '../../../style/assets/Before_and_After_Pillow.jpg'
import BeforeAfterCarSeat from '../../../style/assets/Before_and_After_Car_Seat.jpg'
import { useCallback } from 'react'

interface ImgSize {
  width: `${number}${ImgSizeUnit}`
  height: `${number}${ImgSizeUnit}`
}

type ImgSizeUnit = 'px'

const LARGE_IMG: ImgSize = { width: '400px', height: '500px' }
const MEDIUM_IMG: ImgSize = { width: '360px', height: '450px' }
const SMALL_IMG: ImgSize = { width: '320px', height: '400px' }

const Carousel = () => {
  const { isLargeDevice, isSmallDevice, lowerThreshold, upperThreshold } =
    useDeviceSize()
  const alt = 'Снимка преди и след'

  const getImgSize = useCallback(() => {
    // below 400
    if (upperThreshold(400)) {
      return SMALL_IMG
    }

    // between 576 and 400
    if (isLargeDevice) {
      return LARGE_IMG
    }

    // between 991 and 800
    if (lowerThreshold(800)) {
      return MEDIUM_IMG
    }

    if (upperThreshold(725)) {
      return LARGE_IMG
    }

    // between 725 and 800
    if (upperThreshold(800)) {
      return SMALL_IMG
    }
  }, [isLargeDevice, lowerThreshold, upperThreshold])

  const imgSize = getImgSize()

  const slidesToShow = () => {
    if (isSmallDevice) {
      return 1
    }

    // between 725 and 576
    if (upperThreshold(725)) {
      return 1.3
    }

    // on large device
    return 2.2
  }

  return (
    <CarouselComp
      animation="zoom"
      autoplay={true}
      autoplayInterval={5000}
      cellAlign={Alignment.Center}
      slidesToShow={slidesToShow()}
      wrapAround={true}
      withoutControls={true}
      pauseOnHover={true}
    >
      <img src={BeforeAfterMattress} style={imgSize} alt={alt} loading="lazy" />
      <img src={BeforeAfterCarSeat} style={imgSize} alt={alt} loading="lazy" />
      <img src={BeforeAfterCouch1} style={imgSize} alt={alt} loading="lazy" />
      <img src={BeforeAfterCouch4} style={imgSize} alt={alt} loading="lazy" />
      <img src={BeforeAfterCouch2} style={imgSize} alt={alt} loading="lazy" />
      <img src={BeforeAfter1} style={imgSize} alt={alt} loading="lazy" />
      <img src={BeforeAfter3} style={imgSize} alt={alt} loading="lazy" />
      <img src={BeforeAfter4} style={imgSize} alt={alt} loading="lazy" />
      <img src={BeforeAfter2} style={imgSize} alt={alt} loading="lazy" />
      <img src={BeforeAfterPillow} style={imgSize} alt={alt} loading="lazy" />
      <img src={BeforeAfterCouch3} style={imgSize} alt={alt} loading="lazy" />
    </CarouselComp>
  )
}

export default Carousel
