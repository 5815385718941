import Carousel from '../../../framework/carousel'
import Section from '../../../framework/section'

import './style.css'

const ComparisonSection = () => {
  return (
    <Section>
      <div className="comparison-section">
        <Carousel />
      </div>
    </Section>
  )
}

export default ComparisonSection
