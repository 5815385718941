import { useRef } from 'react'

import Header, { MenuItem, NavItem } from '../../framework/header'
import FloatinButton from '../../presentational/FloatingButton'
import AboutUsSection from '../../presentational/sections/about-us-section'
import ComparisonSection from '../../presentational/sections/comparison-section'
import CopyrightSection from '../../presentational/sections/copyright-section'
import ExplanationSection from '../../presentational/sections/explanation-section'
import FooterSection from '../../presentational/sections/footer-section'
import HeaderSection from '../../presentational/sections/header-section'
import OurVisionSection from '../../presentational/sections/our-vision-section'
import PricesSection from '../../presentational/sections/prices-section'
import ReviewSection from '../../presentational/sections/review-section'

import './style.css'

const App = () => {
  const reviewsRef = useRef<HTMLDivElement>(null)
  const footerRef = useRef<HTMLDivElement>(null)
  const pricesSectionRef = useRef<HTMLDivElement>(null)

  const scrollToReviews = () => {
    if (reviewsRef !== null) {
      reviewsRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      })
    }
  }

  const scrollToPriceList = () => {
    if (pricesSectionRef !== null) {
      pricesSectionRef.current?.scrollIntoView({ behavior: 'smooth' })
    }
  }

  const scrollToFooter = () => {
    if (footerRef !== null) {
      footerRef.current?.scrollIntoView({ behavior: 'smooth' })
    }
  }

  const menuItems: MenuItem[] = [
    { text: 'Ревюта', onClick: scrollToReviews },
    { text: 'Цени', onClick: scrollToPriceList },
    { text: 'Контакти', onClick: scrollToFooter },
  ]

  const links: NavItem[] = [
    {
      itemName: 'Блог',
      to: '/blog/all',
    },
  ]

  return (
    <div className="app">
      <Header menuItems={menuItems} rightSection={links} />
      <main className="app__body">
        <HeaderSection />
        <ComparisonSection />
        <ReviewSection ref={reviewsRef} />
        <ExplanationSection />
        <AboutUsSection />
        <OurVisionSection />
        <PricesSection ref={pricesSectionRef} />
      </main>
      <FooterSection ref={footerRef} />
      <CopyrightSection />
      <FloatinButton />
    </div>
  )
}

export default App
