import { useEffect, RefObject } from 'react'

type MouseOrTouchEvent = MouseEvent | TouchEvent

const useOnClickOutside = <T extends HTMLElement = HTMLElement>(
  ref: RefObject<T>,
  handler: (event: MouseOrTouchEvent) => void,
  dependency = true
): void => {
  useEffect(() => {
    const listener = (event: MouseOrTouchEvent) => {
      const element = ref?.current

      // do nothing if ref's element is clicked
      if (!element || element.contains(event.target as Node)) {
        return
      }

      if (dependency) {
        handler(event)
      }
    }

    document.addEventListener('mousedown', listener)
    document.addEventListener('touchstart', listener)

    return () => {
      document.removeEventListener('touchstart', listener)
      document.removeEventListener('mousedown', listener)
    }
  }, [ref, handler, dependency])
}

export default useOnClickOutside
