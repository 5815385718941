import Card from '../../../framework/card'
import Section from '../../../framework/section'

import './style.css'

const HeaderSection = () => {
  return (
    <Section className="section-banner">
      <div className="header-section">
        <div className="header-section__headline">
          <h1 className="header-section__headline__title">
            ПОЧИСТВАЩИ УСЛУГИ
            <br />
            В
            <br />
            РАЙОНА НА ВАРНА
          </h1>
          <div className="header-section__headline__subtitle__wrapper">
            <span className="header-section__headline__subtitle header-section__service-list1">
              мека мебел
              <span className="header-section__dot" />
              килими
              <span className="header-section__dot" />
              мокети
              <span className="header-section__dot" />
              матраци
            </span>
            <span className="header-section__headline__subtitle  header-section__service-list2">
              домове
              <span className="header-section__dot" />
              офиси
              <span className="header-section__dot" />
              търговски обекти
            </span>
          </div>
        </div>
        <Card />
      </div>
    </Section>
  )
}

export default HeaderSection
