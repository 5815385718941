import { forwardRef } from 'react'

import {
  ADDITIONAL_SERVICES,
  ADDITIONAL_SERVICES_PRICE,
  LEATHER_PRICE,
  LEATHER_ROWS,
  MATTRESSES_HEADER_ROWS,
  MATTRESSES_PRICE,
  SOFT_FURNITURE_PRICE_LIST,
  SOFT_FURNITURE_PRICE_LIST_HEADER_ROWS,
} from '../../../../common/factory/priceLists'
import Section from '../../../framework/section'
import Table from '../../../framework/table'

import './style.css'

const PricesSection = forwardRef<HTMLDivElement>((_, ref) => {
  return (
    <Section ref={ref}>
      <div className="prices-section__wrapper">
        <h1 className="prices-section__title">
          <strong>Ценоразпис</strong>
        </h1>
        <div className="prices-section">
          <Table
            rowItems={SOFT_FURNITURE_PRICE_LIST}
            rowHeaders={SOFT_FURNITURE_PRICE_LIST_HEADER_ROWS}
          />
          <Table
            rowItems={MATTRESSES_PRICE}
            rowHeaders={MATTRESSES_HEADER_ROWS}
          />
          <Table rowItems={LEATHER_PRICE} rowHeaders={LEATHER_ROWS} />
          <Table
            rowItems={ADDITIONAL_SERVICES_PRICE}
            rowHeaders={ADDITIONAL_SERVICES}
          />
        </div>
        <span className="prices-section__disclaimer">
          Минимум стойност на услугата: Варна – 40 лв
        </span>
      </div>
    </Section>
  )
})

PricesSection.displayName = 'PricesSection'

export default PricesSection
