import { forwardRef } from 'react'

import { RATING } from '../../../../common/reviews'
import ReviewList from '../../../framework/review-list'
import Section from '../../../framework/section'

import './style.css'

const ReviewSection = forwardRef<HTMLDivElement>((_, ref) => {
  return (
    <Section ref={ref}>
      <div className="review-section">
        <h1 className="review-section__headline">РЕВЮТА</h1>
        <span className="review-section__rating">{`${RATING} / 5`}</span>
        <ReviewList />
      </div>
    </Section>
  )
})

ReviewSection.displayName = 'ReviewSection'

export default ReviewSection
