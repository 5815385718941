import { COMPANY_NAME } from '../../../../common/factory/contacts'

import './style.css'

const CopyrightSection = () => {
  return (
    <div className="copyright-section">
      <span className="copyright-section__claim">{`© Copyright - ${COMPANY_NAME} ${new Date().getFullYear()}`}</span>
    </div>
  )
}

export default CopyrightSection
