import { Fragment } from 'react'

import { MdLocalPhone, MdFacebook } from 'react-icons/md'
import { Fab } from 'react-tiny-fab'

import { PHONE_NUMBER, FACEBOOK } from '../../common/factory/contacts'
import useDeviceSize from '../../common/hooks/useDeviceSize'

const FloatinButton = () => {
  const { isSmallDevice } = useDeviceSize()
  const trackPhoneNumberClick = (url: Location) => {
    const callback = function () {
      if (typeof url != 'undefined') {
        window.location = url
      }
    }
    // @ts-expect-error
    gtag('event', 'conversion', {
      send_to: 'AW-10892409900/UDsECK2y4bkZEKz488ko',
      event_callback: callback,
    })
    return false
  }

  return (
    <Fragment>
      <a
        href={`tel:${PHONE_NUMBER}`}
        onClick={() => {
          trackPhoneNumberClick(window.location)
        }}
      >
        <Fab
          alwaysShowTitle={false}
          icon={<MdLocalPhone size="30px" />}
          event={'click'}
          mainButtonStyles={{
            backgroundColor: '#00A884',
            color: 'white',
          }}
          style={
            isSmallDevice
              ? { bottom: 12, right: 12, marginRight: 12 }
              : { bottom: 24, right: 24 }
          }
        ></Fab>
      </a>

      <Fab
        alwaysShowTitle={false}
        icon={<MdFacebook size="30px" />}
        style={
          isSmallDevice
            ? { bottom: 84, right: 12, marginRight: 12 }
            : { bottom: 94, right: 24 }
        }
        mainButtonStyles={{
          backgroundColor: '#1877F2',
          color: 'white',
        }}
        onClick={() => window.open(FACEBOOK)}
        event={'click'}
      />
    </Fragment>
  )
}

export default FloatinButton
