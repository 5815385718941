import { Stars } from '../components/framework/review'

interface Review {
  id: number
  date: string
  user_name: string
  review: string
  rating: Stars
}

const RATING = 4.8
const EVALUATIONS = 28
const REVIEWS: Review[] = [
  {
    id: 123,
    date: '2022-09-06 19:19:49',
    user_name: 'Албена',
    review: 'Много сме доволни. Бърза и прецизна работа.',
    rating: 5,
  },
  {
    id: 747509,
    date: '2022-09-06 19:19:49',
    user_name: 'tania',
    review:
      'Много добре изпрани и ароматизирани дивани, фотьойли и табуретки! Добре свършена работа, доволна съм и веднага препоръчах на мои приятелки!',
    rating: 5,
  },
  {
    id: 747414,
    date: '2022-09-05 23:18:43',
    user_name: 'Valentina',
    review: 'Професионално обслужване! Точно и коректно!',
    rating: 5,
  },
  {
    id: 747328,
    date: '2022-09-05 14:49:20',
    user_name: 'Joana',
    review: 'Много съм доволна от услугата! Препоръчвам!',
    rating: 5,
  },
  {
    id: 746756,
    date: '2022-09-01 22:34:35',
    user_name: 'Люба',
    review:
      'Възползвахме се от пране на диван. Очаровани сме от резултата и професионалното отношение. И зключително любезни и старателни. Петната се изчистиха, а диванът беше абсолютно сух за около 4 часа.',
    rating: 5,
  },
  {
    id: 746226,
    date: '2022-08-30 13:47:17',
    user_name: 'Северина',
    review:
      'Момчето е много любезно и коректно. Меката мебел е добре изпрана. Доволна съм, препоръчвам, ще използвам пак.',
    rating: 5,
  },
  {
    id: 745824,
    date: '2022-08-28 11:54:34',
    user_name: 'Radina',
    review:
      "Доволни сме от Marty's cleaning! Досто бързо и умело почисти дивана, който придоби отново цв ят! Ще ползвам отново услугите!",
    rating: 5,
  },
  {
    id: 744301,
    date: '2022-08-20 19:03:47',
    user_name: 'Мария',
    review:
      'Човекът е много старателен, доволна съм от услугата. Бих ползвала отново!',
    rating: 5,
  },
  {
    id: 743858,
    date: '2022-08-18 15:24:08',
    user_name: 'Анна',
    review: 'Препоръчвам на 100%. Дивана е като нов',
    rating: 5,
  },
  {
    id: 742770,
    date: '2022-08-12 18:33:56',
    user_name: 'Йордан',
    review:
      'Много съм доволен. Младо момче, много съвестно в работата си. Подходи със старание към моят стар холов ъгъл. Почисти го много добре. Препоръчвам го!',
    rating: 5,
  },
  {
    id: 738314,
    date: '2022-07-20 12:49:07',
    user_name: 'маргарита',
    review: 'Дограмата изчистена оттук-оттам, стъклата също недоизчистени',
    rating: 2,
  },
  {
    id: 737606,
    date: '2022-07-16 18:02:48',
    user_name: 'мария',
    review: 'Изпра се всичко!',
    rating: 5,
  },
  {
    id: 736750,
    date: '2022-07-11 15:51:36',
    user_name: 'Ангелинка',
    review: 'Точни,старателни и коректни.',
    rating: 4,
  },
  {
    id: 736574,
    date: '2022-07-10 17:14:06',
    user_name: 'Rumiana',
    review:
      'Много съм доволна от извършената услуга.Горещо препоръчвам и на други желаещи.',
    rating: 5,
  },
  {
    id: 734991,
    date: '2022-07-01 14:35:42',
    user_name: 'Сдружение',
    review: 'Чудесна услуга, чудесен екип! Много сме доволни!',
    rating: 5,
  },
  {
    id: 734947,
    date: '2022-07-01 09:50:14',
    user_name: 'Radostina',
    review:
      'Изключително съм доволна от услугата - много професионално, с голямо внимание към спецификат а на детайлите, коректно и най-вече с усмивка. Спокойно мога да дам за пример за отличена оценка и я давам. Без никакво колебание препоръчвам и отново с удоволствие бих ползвала тази фирма. Браво!',
    rating: 5,
  },
  {
    id: 734797,
    date: '2022-06-30 13:49:50',
    user_name: 'deni',
    review:
      'Отлично , качествено , професионално извършена услуга. Вежливо , любезно , коректно отношени е.Препоръчвам . С удоволствие ще ползвам отново услугите за почистване. Изключително много съм доволна.',
    rating: 5,
  },
  {
    id: 734159,
    date: '2022-06-27 11:51:03',
    user_name: 'Ваня',
    review: 'Препоръчвам.Перфектно облужване и отношение.',
    rating: 5,
  },
  {
    id: 733605,
    date: '2022-06-24 12:57:26',
    user_name: 'Продан',
    review:
      'Коректно и бързо обслужване! Работи чисто и съвестно! Щом и жената остана доволна... :D   Ше гата на страна, препоръчвам! :)',
    rating: 5,
  },
  {
    id: 733180,
    date: '2022-06-22 13:27:11',
    user_name: 'Ивелин',
    review: 'Професионално и бързо обслужване',
    rating: 5,
  },
  {
    id: 733141,
    date: '2022-06-22 10:30:29',
    user_name: 'Стиляна',
    review:
      'Бързо и качествено обслужване, много професионално и вежливо отношение. Съобразиха се със желанието ми за ден и час, работят със качествени препарати, бързо и най-важното за нас-Качествено.\r\nЩе използваме услу гата отново!',
    rating: 5,
  },
  {
    id: 731454,
    date: '2022-06-11 21:25:00',
    user_name: 'Павлина',
    review:
      'Много съм доволна! Изключително любезни и старателни. Препоръчвам!',
    rating: 5,
  },
  {
    id: 730462,
    date: '2022-06-07 14:57:07',
    user_name: 'Borislav',
    review:
      'Отлична работа,чудесно почистване! Резултата надмина очакванията ми1 Дивана е като нов! С уд оволствие ще ползвам усугите им отново!',
    rating: 5,
  },
  {
    id: 730446,
    date: '2022-06-07 13:56:21',
    user_name: 'Полина',
    review: 'Професионално извършена услуга!',
    rating: 5,
  },
  {
    id: 727724,
    date: '2022-05-20 19:48:53',
    user_name: 'Т Костадинова',
    review:
      'Отлично свършена работа - бързо и качествено. Дивана, матрака и столовете са като нови, мног о съм доволна. Препоръчвам!',
    rating: 5,
  },
]

export { REVIEWS, RATING, EVALUATIONS }
