import { forwardRef } from 'react'

import classnames from 'classnames'

import './style.css'

interface Prop {
  className?: string
  style?: React.CSSProperties
  children: React.ReactNode
}

const Section = forwardRef<HTMLDivElement, Prop>((props, ref) => {
  return (
    <section className={classnames('section', props.className)} ref={ref}>
      {props.children}
    </section>
  )
})

Section.displayName = 'Section'

export default Section
