import classNames from 'classnames'
import { useState } from 'react'

import './style.css'

interface Prop {
  title: string
  text: string
}

const Article = ({ title, text }: Prop) => {
  const [isTextHidden, setIsTextHidden] = useState(true)

  const toggleIsTextHidden = () => {
    setIsTextHidden((x) => !x)
  }

  return (
    <div className="article__wrapper">
      <div className="article">
        <h1>{title}</h1>
        <p
          className={classNames('article__text', {
            'article__text--hide-paragraph': isTextHidden,
          })}
        >
          {text}
        </p>
        <button
          onClick={toggleIsTextHidden}
          className="article__text__read-more"
        >
          {isTextHidden ? 'покажи повече' : 'покажи по-малко'}
        </button>
      </div>
    </div>
  )
}

export default Article
