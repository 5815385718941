import Article from '../../../framework/article'
import Section from '../../../framework/section'
import { ABOUT_US_TEXT, ABOUT_US_TITLE } from './about-us-text'

import './style.css'

const AboutUsSection = () => {
  return (
    <Section>
      <Article title={ABOUT_US_TITLE} text={ABOUT_US_TEXT}></Article>
    </Section>
  )
}

export default AboutUsSection
