import { CSSProperties, forwardRef } from 'react'

import classnames from 'classnames'

import { ServicePrice } from '../../../common/type/service-price'
import './style.css'

interface Prop {
  className?: string
  rowHeaders?: string[]
  rowItems: ServicePrice[]
  style?: CSSProperties
}

const Table = forwardRef<HTMLTableElement, Prop>((props, ref) => {
  return (
    <table
      className={classnames('table', props.className)}
      ref={ref}
      style={props.style}
    >
      <thead>
        <tr>
          {props?.rowHeaders?.map((x: string, idx: number) => (
            <th colSpan={2} key={`rowHeaderIdx${idx}`}>
              {x}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {props.rowItems.map((x: ServicePrice, idx: number) => {
          const unit: string = x.unit ?? 'лв'

          return (
            <tr
              key={`priceList${idx}`}
              className={classnames({ 'table__row--active': idx % 2 === 0 })}
            >
              <td>
                {`${x.service} `}
                {x.extra_info && (
                  <span className="table__row--extra-info">{x.extra_info}</span>
                )}
              </td>
              <td className="table__right-column">{`${x.price} ${unit}`}</td>
            </tr>
          )
        })}
      </tbody>
    </table>
  )
})

Table.displayName = 'Table'

export default Table
